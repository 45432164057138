<template>
  <div class="popover-facture-avoir">
    <v-menu :close-on-content-click="false" right offset-x>
      <template v-slot:activator="{ on, attrs }">
        <div class="block-icon-table-facture" v-on="on" v-bind="attrs">
          <font-awesome-icon icon="info-circle" class="" />
        </div>
      </template>
      <v-card>
        <v-card-text class="body-card center-text">
          <v-list-item
            v-for="(facture, i) in factureAvoir"
            :key="i"
            class="list-block"
          >
            <v-list-item-content>
              <v-list-item-title>
                <div class="flex-div">
                  <div>{{ facture.num }} , {{ facture.total_ttc }} €</div>
                  <div class="flex-div ml-3">
                    <!-- <v-tooltip
                      bottom
                      color="#311B92"
                   
                    >
                      <template v-slot:activator="{ on, attrs }"> -->
                    <div
                      v-if="permissionTelecharger"
                      class="block-icon-table-facture mr-3"
                      @click="clickButton(facture.id)"
                      title="Télécharger Facture Avoir"
                    >
                      <font-awesome-icon
                        icon="arrow-alt-circle-down"
                        class=""
                      />
                    </div>
                    <!-- </template> -->
                    <!-- <span>Télécharger Facture Avoir</span>
                    </v-tooltip> -->

                    <!-- <v-tooltip
                      bottom
                      color="#311B92"
                     
                    >
                      <template v-slot:activator="{ on, attrs }"> -->
                    <div
                      v-if="permissionVoirFacture"
                      class="block-icon-table-facture"
                      @click.prevent="handleShowFacture(facture.id)"
                      title="Visualiser Facture Avoir"
                    >
                      <font-awesome-icon class="" icon="eye" />
                    </div>
                    <!-- </template>
                      <span>Visualiser Facture Avoir</span>
                    </v-tooltip> -->
                  </div>
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-menu>
    <v-dialog
      v-model="VisualiserFacture"
      max-width="850px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Visualiser Facture Avoir</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeModal"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text">
          <iframe
            height="1100"
            width="760"
            :src="factureToShow + '#toolbar=0'"
          ></iframe>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    factureAvoir: { required: true },
    permissionTelecharger: { required: false },
    permissionVoirFacture: { required: false },
  },
  components: {},
  data() {
    return {
      error: false,
      VisualiserFacture: false,
      factureToShow: null
    };
  },
  computed: {
    ...mapGetters([])
  },
  mounted() {},
  methods: {
    ...mapActions([
      'exportPdfFactureSci',
      'displayFact',
      'exportPdfFactureAvoirDynamic'
    ]),
    closeModal() {
      this.VisualiserFacture = false;
      this.error = false;
      this.factureToShow = null;
    },
    async handleShowFacture(id) {
      const response = await this.displayFact(id);
      this.VisualiserFacture = true;
      if (response.success == true) {
        this.factureToShow = response.response;
      }
    },
    clickButton(id) {
      if (this.type == 'master') {
        this.exportPdfFactureAvoirDynamic({ id: id });
      } else {
        this.exportPdfFactureSci({
          id: id
        });
      }
    }
  }
};
</script>
